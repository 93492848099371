import React, { memo, useState } from 'react';
import validator from 'validator'
import {styled, TextField } from '@mui/material';
import api from '../../api/api';
import ModalInviteCounterPartyAlert from '../Modal/ModalInviteCounterPartyAlert';
import ModalWelcome from '../Modal/ModalWelcome';
import InputMask from 'react-input-mask';

const MIN__LENGTH = 3;
const CORRECT__LENGTH = [10, 12];
const MAX__LENGTH = 15;
const MESSAGE_EMAIL_IS_INVALID = "Email не соответствует требованиям";
const MESSAGE_PHONE_IS_INVALID = "Такого телефона не существует";
const MESSAGE_INVALID_BIN = "Введите БИН из 12 символов";
const MESSAGE_INVALID_INN = "Введите ИНН из 10 символов для юр. лиц или 12 для физ. лиц";
const MESSAGE_INVALID_KPP = "Введите КПП из 9 символов";
const MESSAGE_BIN_REQUIRED = "Введите БИН компании";
const MESSAGE_MAX_SYMBOL = "Максимальное количество символов 15";

const CustomInput = styled(TextField)(({ theme }) => ({
    '.MuiInputLabel-root': {
      display: 'none'
    },
    '.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #8ab528',
      fontFamily: 'IBM Plex Sans',
      fontWeight: '400',
      fontSize: '0.83vw',
      height:'3.1vw',
    },
    'label,&.Mui-focused': {
      border: '1px solid ##BDBDB',  
    },
    '&.MuiTextField-root':{
      width:'calc(1600/1920*100vw)',
      background:'#fff',
      fontFamily: 'IBM Plex Sans',
      fontWeight: '400',
      fontSize: '0.83vw',
      height:'3.1vw',
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 }
}));

const CastStyle = {
  height:'3.1vw !important',
  fontFamily: 'IBM Plex Sans !important',
  fontWeight: '400 !important',
  fontSize: '0.83vw !important',   
  '.MuiInputBase-root' : {
    height:'3.1vw !important',
    fontFamily: 'IBM Plex Sans !important',
    fontWeight: '400 !important',
    fontSize: '0.83vw !important',
    borderRadius: '0',
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #BDBDBD',
    },
    "&:focused .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #8ab528',
    },
    "&:focus-within .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #8ab528',
    },
  }
}

const NewCounterparty = ({refetchData}) => {
const [netWorkError,setNetWorkError] = useState()
const [serverError,setServerError] = useState();
const [openModalEmail,setOpenModalEmail] = useState(false);
const [isOpen, setIsOpen] = useState(false);

const togglePopupEmail = () => {
    setOpenModalEmail(prevState => !prevState);
}

const initialErrorsList = {  
  email: "",
  binValue: "",
  kppValue: "",
  innValue: "",
  phone: ""
}

const [errorsList, setErrorsList] = useState(initialErrorsList)

const [register, setRegister] = useState(() => {
    return {
      innValue: "",
      kppValue: "",
      binValue: "",
      email: "",
      comment:"",
      phone:""
    }
})

const removeFieldError = (field) => {
    setErrorsList(prevState => ({...prevState, [`${field}`]: ''}))
} 

const changeInputRegister = event => {   
    const changedValue = event.target.value
    const fieldName = event.target.name
    removeFieldError(fieldName);
    setRegister(prev => {
        return {
            ...prev,
            [fieldName]: changedValue,
        }
    })
}

    const sendToBE = async(event) => {
      let wasError = false;

      const {
        innValue,
        kppValue,
        binValue,
        comment,
        phone
      } = register;
      const email = register.email.trim();
      if (!CORRECT__LENGTH.includes(innValue.length) && selectedCountry === 'ru') {
        setErrorsList(prevState => ({...prevState, innValue: MESSAGE_INVALID_INN}))
        wasError = true;
      }
      if (kppValue.length !== 9 && selectedCountry === 'ru') {
        setErrorsList(prevState => ({...prevState, kppValue: MESSAGE_INVALID_KPP}))
        wasError = true;
      }
      if (binValue.length !== CORRECT__LENGTH[1] && selectedCountry === 'kz') {
        setErrorsList(prevState => ({...prevState, binValue: MESSAGE_INVALID_BIN}))
        wasError = true;
      }
      if (binValue.length < MIN__LENGTH && selectedCountry === 'kz') {
        setErrorsList(prevState => ({...prevState, binValue: MESSAGE_BIN_REQUIRED}))
        wasError = true;
      }
      if (!validator.isEmail(email)) {
        setErrorsList(prevState => ({...prevState, email: MESSAGE_EMAIL_IS_INVALID}))
        wasError = true;
      }
      if (phone && !validator.isMobilePhone(phone, ['ru-RU', 'kk-KZ'], {strictMode:true})) {
        setErrorsList(prevState => ({...prevState, phone: MESSAGE_PHONE_IS_INVALID}))
        wasError = true;
      }
      if (binValue.length > MAX__LENGTH && selectedCountry === 'kz') {
        setErrorsList(prevState => ({...prevState, binValue: MESSAGE_MAX_SYMBOL}))
        wasError = true;
      }
      if (wasError) {
        return;
      }
        event.preventDefault();
        const formData = new FormData();
        formData.append('identification_number', innValue ? innValue : binValue);
        formData.append('kpp', kppValue);
        formData.append('email', email);
        formData.append('message', comment);
        formData.append('country_code', selectedCountry);
        formData.append('phone', phone)
        try {
          const res = await api.post( "/invite-counterparties", formData);
          if (res.data) {                        
            togglePopupEmail()   
            register.comment="";   
            register.email=""; 
            register.binValue="";
            register.kppValue="";
            register.innValue="";
            register.phone="";
            setServerError(null);
            setNetWorkError(null); 
            refetchData();            
          };
        } catch (e) {
          if (e.message === "Network Error") {    
            setNetWorkError(e.message)
          };    
          setServerError(e.response.data.message); 
        }        
      }

    const [selectedCountry, setSelectedCountry] = useState('kz');
    const handleOptionCountry = (e) => {
        setSelectedCountry(e.target.value);
    };
    const onCountryChange = (event) => {
        const selectedCountry = event.target.value;
        setSelectedCountry(selectedCountry)
    }
    const countries = [
        {
            name: "Россия",
            code: "ru"
        },
        {
            name:"Казахстан",
            code: "kz"
        }
    ];

    return (
        <div className='new-counterparty'>
            <h1>
                Приглашение контрагентов
            </h1>
            <p>
                Международный ЭДО в сервисе SimpSign начинается с отправки приглашения вашему контрагенту.
                Приглашение сообщает вашему контрагенту, что вы готовы обмениваться с ним электронными документами.
            </p>
            <p>
                Важно также лично рассказать контрагентам, что вы перешли на международный ЭДО в SimpSign. Особенно тем,
                кто еще не работает в нем. Это позволит запустить обмен документами быстрее.
            </p>
            <p>
                Для отправки приглашения введите БИН организации, с которой собираетесь обмениваться документами и email
                сотрудника, отвечающего за ЭДО в этой компании. После принятия приглашения вашим контрагентом вы можете
                начать с ним документооборот!
            </p>

            <div>
                <select className="new-counterparty-dropdown" value={selectedCountry} onChange={handleOptionCountry}
                        onClick={onCountryChange}>
                    {countries.map((el) => <option value={el.code} key={el.code}>{el.name}</option>)}
                </select>
            </div>
            {selectedCountry === 'ru' ?
                <div className='new-couterparty-lable' style={{paddingBottom: '5px'}}>
                    ИНН организации в России, с которой вы хотите установить ЭДО:
                </div> :
                <div className='new-couterparty-lable' style={{paddingBottom: '5px'}}>
                    БИН организации в Казахстане, с которой вы хотите установить ЭДО:
                </div>
            }
            {selectedCountry === 'ru' ?
              <CustomInput
                sx={CastStyle}
                name="innValue"
                variant="outlined"
                value={register.innValue}
                onChange={changeInputRegister}
              /> :
              <CustomInput
                  sx={CastStyle}
                  name="binValue"
                  variant="outlined"
                  value={register.binValue}
                  onChange={changeInputRegister}
              />
            }
            {selectedCountry === 'ru' && Boolean(errorsList?.innValue) ? (<div className="valid-error">{errorsList.innValue}</div>) : null}
            {selectedCountry === 'kz' && Boolean(errorsList?.binValue) ? (<div className="valid-error">{errorsList.binValue}</div>) : null}

            {selectedCountry === 'ru' ?
                <div className='new-couterparty-lable' style={{paddingBottom: '5px'}}>
                    КПП организации в России, с которой вы хотите установить ЭДО:
                </div> :
                null
            }
            {selectedCountry === 'ru' ?
                <CustomInput
                    sx={CastStyle}
                    name="kppValue"
                    variant="outlined"
                    value={register.kppValue}
                    onChange={changeInputRegister}
                /> :
                null
            }

            {selectedCountry === 'ru' && Boolean(errorsList?.kppValue) ? (<div className="valid-error">{errorsList.kppValue}</div>) : null}
            < div className='new-couterparty-lable' style={{paddingBottom: '5px'}}>
                Email сотрудника, отвечающего за ЭДО в этой компании:
            </div>
            <CustomInput
                sx={CastStyle}
                variant="outlined"
                name="email"
                value={register.email}
                onChange={changeInputRegister}
            />
            {Boolean(errorsList?.email) ? (<div className="valid-error">{errorsList.email}</div>) : null}
            < div className='new-couterparty-lable' style={{paddingBottom: '5px'}}>
                Телефон сотрудника, отвечающего за ЭДО в этой компании:
            </div>
            <InputMask mask="+79999999999" onChange={changeInputRegister} value={register.phone}>
              {(inputProps) => <CustomInput
                {...inputProps}
                sx={CastStyle}
                variant="outlined"
                name="phone"
                type="tel"
                pattern="[\+]\d{2}[\(]\d{2}[\)]\d{4}[\-]\d{4}"
              />}

            </InputMask>
            {Boolean(errorsList?.phone) ? (<div className="valid-error">{errorsList.phone}</div>) : null}
            <div className='new-couterparty-lable' style={{paddingBottom: '5px'}}>
                {/* Добавить комментарий: */}
            </div>
            {/* <textarea            
            className='text-area'
            name="comment"
            style={{width: 'calc(1600/1920*100vw)'}}
                          
            value={register.comment}                
            onChange={changeInputRegister}
            />  */}
            <button
                className="form-button button-signature"
                type="submit"
                onClick={sendToBE}
            >
                Отправить приглашение
            </button>
            {netWorkError && (
                <p className="form-error">
            <span className="valid-error">
              На сервере произошла непредвиденная ошибка. Обратитесь в техническую поддержку.
            </span>
                </p>
            )}
            {serverError && (
                <p className="form-error">
            <span className="valid-error">
           {serverError}
            </span>
                </p>
            )}
            {openModalEmail &&
                <ModalInviteCounterPartyAlert
                    handleClose={togglePopupEmail}
                />
            }
            <ModalWelcome
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </div>
    );
};

export default memo(NewCounterparty);
